import API, { graphqlOperation } from '@aws-amplify/api';
import PubSub from '@aws-amplify/pubsub';
import React, { useEffect, useReducer } from 'react';
import { v4 as uuid } from 'uuid';
import awsconfig from './aws-exports';
import { createMlcsurmcSystemSetting, updateMlcsurmcSystemSetting } from './graphql/mutations';
import { listMlcsurmcSystemSettings } from './graphql/queries';
import { onCreateMlcsurmcSystemSetting } from './graphql/subscriptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

API.configure(awsconfig);
PubSub.configure(awsconfig);

// Action Types
const QUERY = 'QUERY';
const SUBSCRIPTION = 'SUBSCRIPTION';

const Latest = "Latest";

const initialState = {
    mlcsurmcSystemSettings: [],
}

const reducer = (state, action) => {
    switch (action.type) {
        case QUERY:
            return { ...state, mlcsurmcSystemSettings: action.mlcsurmcSystemSettings };
        case SUBSCRIPTION:
            return { ...state, mlcsurmcSystemSettings: [action.mlcsurmcSystemSetting] };
        default:
            return state;
    }
}

async function getMlcsurmcSystemSetting(timestamp) {
    var mlcsurmcSystemSettingData;
    try {
        mlcsurmcSystemSettingData = await API.graphql(graphqlOperation(listMlcsurmcSystemSettings, {
            filter: {
                Timestamp: {
                    eq: timestamp
                }
            }, limit: 1000
        }));
    } catch (error) {
        console.log(error);
        return (error)
    }
    return (mlcsurmcSystemSettingData.data.listMlcsurmcSystemSettings.items);
}

async function createNewMlcsurmcSystemSetting(input) {
    try {
        await API.graphql(graphqlOperation(createMlcsurmcSystemSetting, { input }));
    } catch (error) {
        console.log(error);
        return (error)
    }
}

async function updateExistingMlcsurmcSystemSetting(input) {
    try {
        await API.graphql(graphqlOperation(updateMlcsurmcSystemSetting, { input }));
    } catch (error) {
        console.log(error);
        return (error);
    }
}

async function defaultMlcsurmcSystemSettings() {
    // Get Default System Setting
    var defaultSystemSetting;
    try {
        defaultSystemSetting = await getMlcsurmcSystemSetting("Default");
    } catch (error) {
        console.log(error);
        return (error);
    }
    console.log('defaultSystemSetting', defaultSystemSetting);

    if (defaultSystemSetting.length > 1) {
        console.log("More than one record found for Default System Setting.")
        return;
    }

    // Get Latest System Settings
    var latestSystemSetting;
    try {
        latestSystemSetting = await getMlcsurmcSystemSetting(Latest);
    } catch (error) {
        console.log(error);
        return (error);
    }
    console.log('latestSystemSetting', latestSystemSetting)

    if (latestSystemSetting.length > 1) {
        console.log("More than one record found for Latest System Setting.");
        return;
    }

    // IF Default System Settings are different to Latest System Settings
    if (!(defaultSystemSetting[0].StartTime === latestSystemSetting[0].StartTime && defaultSystemSetting[0].EndTime === latestSystemSetting[0].EndTime)) {
        // Create new System Setting item with Timestamp
        createNewMlcsurmcSystemSetting({
            Timestamp: new Date().toISOString(),
            StartTime: defaultSystemSetting[0].StartTime,
            EndTime: defaultSystemSetting[0].EndTime
        });
        // Update Latest System Settings with Default values
        updateExistingMlcsurmcSystemSetting({
            id: latestSystemSetting[0].id,
            StartTime: defaultSystemSetting[0].StartTime,
            EndTime: defaultSystemSetting[0].EndTime
        });
    }
}

async function updateMlcsurmcSystemSettings(pauseUntil) {
    const startTime = document.getElementById("startTime").value;
    const endTime = document.getElementById("endTime").value;

    // Get Latest System Settings
    var latestSystemSetting;
    try {
        latestSystemSetting = await getMlcsurmcSystemSetting(Latest);
    } catch (error) {
        console.log(error);
        return (error);
    }
    console.log('latestSystemSetting', latestSystemSetting);

    if (latestSystemSetting.length > 1) {
        console.log("More than one record found for Latest System Setting.")
        return;
    }

    // IF Latest System Settings are different to the inputted ones
    if (!(startTime === latestSystemSetting[0].StartTime && endTime === latestSystemSetting[0].EndTime) || pauseUntil !== null) {
        // Create new System Setting item with Timestamp
        createNewMlcsurmcSystemSetting({
            Timestamp: new Date().toISOString(),
            StartTime: startTime,
            EndTime: endTime,
            PauseUntil: pauseUntil
        });
        //Update Latest System Settings with specified values
        updateExistingMlcsurmcSystemSetting({
            id: latestSystemSetting[0].id,
            StartTime: startTime,
            EndTime: endTime,
            PauseUntil: pauseUntil ? pauseUntil.toISOString() : null
        });
    }
}

function SystemSettingsPause() {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        async function getData() {
            const latestMlcsurmcSystemSetting = await getMlcsurmcSystemSetting(Latest);
            dispatch({ type: QUERY, mlcsurmcSystemSettings: latestMlcsurmcSystemSetting });
        }
        getData();

        const subscription = API.graphql(graphqlOperation(onCreateMlcsurmcSystemSetting)).subscribe({
            next: (eventData) => {
                dispatch({ type: SUBSCRIPTION, mlcsurmcSystemSetting: eventData.value.data.onCreateMlcsurmcSystemSetting });
            }
        });
        return () => subscription.unsubscribe();
    }, []);

    return (
        <span>
            {
                state.mlcsurmcSystemSettings.length === 1 && state.mlcsurmcSystemSettings[0].PauseUntil ?
                    state.mlcsurmcSystemSettings.map((mlcsurmcSystemSetting) =>
                        <div key={mlcsurmcSystemSetting.id}>
                            <button className="nav-link btn btn-primary mr-2" type="button" onClick={() => { updateMlcsurmcSystemSettings() }} >
                                <FontAwesomeIcon icon={faPlay} />
                            </button>
                        </div>
                    )
                    :
                    <div className="btn-group dropleft">
                        <button className="nav-link btn btn-primary dropdown-toggle mr-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FontAwesomeIcon icon={faPause} />
                        </button>
                        <form className="dropdown-menu p-4">
                            <button type="button" className="dropdown-item" onClick={() => { updateMlcsurmcSystemSettings(new Date(new Date().setMinutes(new Date().getMinutes() + 10))) }}>10 Minutes</button>
                            <button type="button" className="dropdown-item" onClick={() => { updateMlcsurmcSystemSettings(new Date(new Date().setMinutes(new Date().getMinutes() + 30))) }}>30 Minutes</button>
                            <button type="button" className="dropdown-item" onClick={() => { updateMlcsurmcSystemSettings(new Date(new Date().setMinutes(new Date().getMinutes() + 60))) }}>1 Hour</button>
                            <button type="button" className="dropdown-item" onClick={() => { updateMlcsurmcSystemSettings(new Date(new Date().setMinutes(new Date().getMinutes() + 5256000))) }}>Indefinitely</button>
                            <div className="dropdown-divider"></div>
                            <div className="form-group">
                                <label htmlFor="custom-pause-until">Until</label>
                                <input
                                    type="time"
                                    id="custom-pause"
                                    className="form-control"
                                    name="custom-pause-until"
                                    defaultValue={`${new Date().getHours().toString().padStart(2, "0")}:${new Date().getMinutes()}`}
                                />
                            </div>
                            <button type="button" className="btn btn-primary" onClick={() => {
                                const customPause = document.getElementById("custom-pause").value;
                                const hour = customPause.substring(0, 2);
                                const minute = customPause.substring(3, 5);
                                updateMlcsurmcSystemSettings(new Date(new Date().setHours(hour, minute)));
                            }}>Confirm</button>
                        </form>
                    </div>
            }
        </span>

    )
}

function SystemSettingsModalContent() {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        async function getData() {
            const latestMlcsurmcSystemSetting = await getMlcsurmcSystemSetting(Latest);
            dispatch({ type: QUERY, mlcsurmcSystemSettings: latestMlcsurmcSystemSetting });
        }
        getData();

        const subscription = API.graphql(graphqlOperation(onCreateMlcsurmcSystemSetting)).subscribe({
            next: (eventData) => {
                dispatch({ type: SUBSCRIPTION, mlcsurmcSystemSetting: eventData.value.data.onCreateMlcsurmcSystemSetting });
            }
        });
        return () => subscription.unsubscribe();
    }, []);

    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">System Settings</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div id="system-settings-alert" className="alert d-none" role="alert"></div>
                <form>
                    <div className="form-row">
                        <h6>Hours of operation</h6>
                    </div>
                    {
                        state.mlcsurmcSystemSettings.length === 1 ?
                            state.mlcsurmcSystemSettings.map((mlcsurmcSystemSetting) =>
                                <div className="form-row" key={mlcsurmcSystemSetting.id}>
                                    <div className="col form-group">
                                        <label htmlFor="system-settings-start-time">
                                            Start time
                                <i className="fa fa-info-circle" data-toggle="tooltip" data-placement="right" title="The time (of day) when the system starts to make outbound calls."></i>
                                        </label>
                                        <input
                                            id="startTime"
                                            className="form-control"
                                            type="time"
                                            defaultValue={mlcsurmcSystemSetting.StartTime}
                                            min="00:00"
                                            max={mlcsurmcSystemSetting.EndTime}
                                            onChange={(event) => { document.getElementById("endTime").min = event.target.value; }}
                                        />
                                    </div>

                                    <div className="col form-group">
                                        <label htmlFor="system-settings-start-time">
                                            End time
                                            <i className="fa fa-info-circle" data-toggle="tooltip" data-placement="right" title="The time (of day) when the system ceases to make outbound calls."></i>
                                        </label>
                                        <input
                                            id="endTime"
                                            className="form-control"
                                            type="time"
                                            defaultValue={mlcsurmcSystemSetting.EndTime}
                                            min={mlcsurmcSystemSetting.StartTime}
                                            max="23:59"
                                            onChange={(event) => { document.getElementById("startTime").max = event.target.value; }}
                                        />
                                    </div>
                                </div>
                            )
                            :
                            <p>Error</p>
                    }
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary mr-1 mb-1" onClick={async function clearMlcsurmcSystemSettings() {
                    var latestMlcsurmcSystemSetting = await getMlcsurmcSystemSetting(Latest);
                    latestMlcsurmcSystemSetting = latestMlcsurmcSystemSetting[0];
                    // Generate a dummy UUID so the latest system settings are displayed.
                    latestMlcsurmcSystemSetting.id = uuid();
                    dispatch({ type: SUBSCRIPTION, mlcsurmcSystemSetting: latestMlcsurmcSystemSetting });
                }}>Clear</button>
                <button type="button" className="btn btn-primary mr-1 mb-1" onClick={() => { defaultMlcsurmcSystemSettings() }}>Default</button>
                <button type="button" className="btn btn-primary mr-1 mb-1" onClick={() => { updateMlcsurmcSystemSettings() }}>Update</button>
            </div>
        </div>
    )
}

export default function TransitionsModal() {
    return (
        <div className="col-md-auto ml-auto">
            <ul className="nav">
                <li className="nav-item">
                    <SystemSettingsPause />
                </li>
                <li className="nav-item">
                    <button type="button" className="nav-link btn btn-primary" data-toggle="modal" data-target="#systemSettingsModal">
                        <FontAwesomeIcon icon={faCog} />
                    </button>
                </li>
            </ul>
            <div className="modal fade" id="systemSettingsModal" tabIndex="-1" role="dialog" aria-labelledby="systemSettingsModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <SystemSettingsModalContent />
                </div>
            </div>
        </div>
    )
}