/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMlcsurmcCallLog = `subscription OnCreateMlcsurmcCallLog {
  onCreateMlcsurmcCallLog {
    ContactId
    CallInProgess
    CallType
    ContactAttempt
    CustomerPhoneNumber
    DateTime
    DisconnectTimestamp
    ICGN
    InitiationTimestamp
    Message
    Outcome
  }
}
`;
export const onUpdateMlcsurmcCallLog = `subscription OnUpdateMlcsurmcCallLog {
  onUpdateMlcsurmcCallLog {
    ContactId
    CallInProgess
    CallType
    ContactAttempt
    CustomerPhoneNumber
    DateTime
    DisconnectTimestamp
    ICGN
    InitiationTimestamp
    Message
    Outcome
  }
}
`;
export const onDeleteMlcsurmcCallLog = `subscription OnDeleteMlcsurmcCallLog {
  onDeleteMlcsurmcCallLog {
    ContactId
    CallInProgess
    CallType
    ContactAttempt
    CustomerPhoneNumber
    DateTime
    DisconnectTimestamp
    ICGN
    InitiationTimestamp
    Message
    Outcome
  }
}
`;
export const onCreateMlcsurmcSystemSetting = `subscription OnCreateMlcsurmcSystemSetting {
  onCreateMlcsurmcSystemSetting {
    id
    Timestamp
    StartTime
    EndTime
    PauseUntil
  }
}
`;
export const onUpdateMlcsurmcSystemSetting = `subscription OnUpdateMlcsurmcSystemSetting {
  onUpdateMlcsurmcSystemSetting {
    id
    Timestamp
    StartTime
    EndTime
    PauseUntil
  }
}
`;
export const onDeleteMlcsurmcSystemSetting = `subscription OnDeleteMlcsurmcSystemSetting {
  onDeleteMlcsurmcSystemSetting {
    id
    Timestamp
    StartTime
    EndTime
    PauseUntil
  }
}
`;
export const onCreateMlcsurmcImport = `subscription OnCreateMlcsurmcImport {
  onCreateMlcsurmcImport {
    ImportTimestamp
    Filename
    InitialCallVolume
    RemainingCallVolume
    LatestImportTimestamp
    Result_Outcome_LeftVoicemail
    Result_Outcome_CallGuardian
    Result_Outcome_HungUp1
    Result_Outcome_HungUp2
    Result_Outcome_HungUp3
    Result_Outcome_Mishear
    Result_Outcome_Refused
    Result_Outcome_Transferred
    Result_Outcome_TransferSuccess
    Result_Outcome_TransferFail
    Result_Outcome_TransferTimeout
    Result_Outcome_TransferError
  }
}
`;
export const onUpdateMlcsurmcImport = `subscription OnUpdateMlcsurmcImport {
  onUpdateMlcsurmcImport {
    ImportTimestamp
    Filename
    InitialCallVolume
    RemainingCallVolume
    LatestImportTimestamp
    Result_Outcome_LeftVoicemail
    Result_Outcome_CallGuardian
    Result_Outcome_HungUp1
    Result_Outcome_HungUp2
    Result_Outcome_HungUp3
    Result_Outcome_Mishear
    Result_Outcome_Refused
    Result_Outcome_Transferred
    Result_Outcome_TransferSuccess
    Result_Outcome_TransferFail
    Result_Outcome_TransferTimeout
    Result_Outcome_TransferError
  }
}
`;
export const onDeleteMlcsurmcImport = `subscription OnDeleteMlcsurmcImport {
  onDeleteMlcsurmcImport {
    ImportTimestamp
    Filename
    InitialCallVolume
    RemainingCallVolume
    LatestImportTimestamp
    Result_Outcome_LeftVoicemail
    Result_Outcome_CallGuardian
    Result_Outcome_HungUp1
    Result_Outcome_HungUp2
    Result_Outcome_HungUp3
    Result_Outcome_Mishear
    Result_Outcome_Refused
    Result_Outcome_Transferred
    Result_Outcome_TransferSuccess
    Result_Outcome_TransferFail
    Result_Outcome_TransferTimeout
    Result_Outcome_TransferError
  }
}
`;
