// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://nxtf4z4eh5gf5m3jcw5kf6r6cm.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "react-portal-20200124120022-hostingbucket-test",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "http://react-portal-20200124120022-hostingbucket-test.s3-website.eu-west-2.amazonaws.com",
    "aws_cognito_identity_pool_id": "eu-west-2:cbc983b0-0bd7-4bb9-a2a4-919d3675a4d8",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_cPNLNFdqd",
    "aws_user_pools_web_client_id": "5sdnj46ov4kpp8ml51lqt5kqpj",
    "oauth": {},
    "aws_user_files_s3_bucket": "mlcsurmc-bookingstest-test",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
