
import { Auth } from 'aws-amplify';
import React from 'react';

function CustomGreetings() {
  return (
   <div className="navbar navbar-expand-lg navbar-dark bg-dark">
     <div className="navbar-brand">
         <img src="arcuslogosml.png" height="30" alt="Arcus Global Logo" />
         <span className="ml-3">Midlands and Lancashire CSU RMC</span>
     </div>
     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
         <span className="navbar-toggler-icon"></span>
     </button>
 
     <div className="collapse navbar-collapse" id="navbarSupportedContent">
         <ul className="navbar-nav ml-auto">
             <li className="nav-item">
                <a className="nav-link" type="button" onClick={() => {
                  Auth.signOut()
                    .catch(err => console.log(err));
                }}>
                  Sign out
                </a>
             </li>
         </ul>
     </div>
   </div>
  )
 }

export default CustomGreetings