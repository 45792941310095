/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMlcsurmcCallLog = `query GetMlcsurmcCallLog($id: ID!) {
  getMlcsurmcCallLog(id: $id) {
    ContactId
    CallInProgess
    CallType
    ContactAttempt
    CustomerPhoneNumber
    DateTime
    DisconnectTimestamp
    ICGN
    InitiationTimestamp
    Message
    Outcome
  }
}
`;
export const listMlcsurmcCallLogs = `query ListMlcsurmcCallLogs(
  $filter: ModelMlcsurmcCallLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listMlcsurmcCallLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      ContactId
      CallInProgess
      CallType
      ContactAttempt
      CustomerPhoneNumber
      DateTime
      DisconnectTimestamp
      ICGN
      InitiationTimestamp
      Message
      Outcome
    }
    nextToken
  }
}
`;
export const getMlcsurmcSystemSetting = `query GetMlcsurmcSystemSetting($id: ID!) {
  getMlcsurmcSystemSetting(id: $id) {
    id
    Timestamp
    StartTime
    EndTime
    PauseUntil
  }
}
`;
export const listMlcsurmcSystemSettings = `query ListMlcsurmcSystemSettings(
  $filter: ModelMlcsurmcSystemSettingFilterInput
  $limit: Int
  $nextToken: String
) {
  listMlcsurmcSystemSettings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      Timestamp
      StartTime
      EndTime
      PauseUntil
    }
    nextToken
  }
}
`;
export const getMlcsurmcImport = `query GetMlcsurmcImport($id: ID!) {
  getMlcsurmcImport(id: $id) {
    ImportTimestamp
    Filename
    InitialCallVolume
    RemainingCallVolume
    LatestImportTimestamp
    Result_Outcome_LeftVoicemail
    Result_Outcome_CallGuardian
    Result_Outcome_HungUp1
    Result_Outcome_HungUp2
    Result_Outcome_HungUp3
    Result_Outcome_Mishear
    Result_Outcome_Refused
    Result_Outcome_Transferred
    Result_Outcome_TransferSuccess
    Result_Outcome_TransferFail
    Result_Outcome_TransferTimeout
    Result_Outcome_TransferError
  }
}
`;
export const listMlcsurmcImports = `query ListMlcsurmcImports(
  $filter: ModelMlcsurmcImportFilterInput
  $limit: Int
  $nextToken: String
) {
  listMlcsurmcImports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      ImportTimestamp
      Filename
      InitialCallVolume
      RemainingCallVolume
      LatestImportTimestamp
      Result_Outcome_LeftVoicemail
      Result_Outcome_CallGuardian
      Result_Outcome_HungUp1
      Result_Outcome_HungUp2
      Result_Outcome_HungUp3
      Result_Outcome_Mishear
      Result_Outcome_Refused
      Result_Outcome_Transferred
      Result_Outcome_TransferSuccess
      Result_Outcome_TransferFail
      Result_Outcome_TransferTimeout
      Result_Outcome_TransferError
    }
    nextToken
  }
}
`;
