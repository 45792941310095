import Amplify from 'aws-amplify';
import { ConfirmSignIn, ForgotPassword, Loading, RequireNewPassword, SignIn, withAuthenticator } from 'aws-amplify-react';
import React from 'react';
import awsconfig from './aws-exports';
import CallLog from './CallLog';
import CustomGreetings from './CustomGreetings';
import ImportData from './ImportData';
import Outcomes from './Outcomes';
import S3CsvUploadModal from './S3CsvUploadModal';
import SystemSettings from './SystemSettings';

// Get the aws resources configuration parameters
Amplify.configure(awsconfig);

function App() {
  document.getElementsByTagName("html")[0].classList.add("h-100");
  document.body.classList += "d-flex flex-column h-100";
  document.getElementById("root").classList += "d-flex flex-column h-100";

  return (
    <div className="d-flex flex-column h-100">
      <CustomGreetings />
      <div className="container-fluid">
        <div className="row mt-2">
          <S3CsvUploadModal />
          <ImportData />
          <SystemSettings />
        </div>
        <div className="row">
          <div className="col">
            <CallLog />
          </div>
        </div>
      </div>
      <div className="container-fluid mt-auto">
        <div className="row">
          <div className="col">
            <Outcomes />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(App, {
  // Render a sign out button once logged in
  includeGreetings: true,
  // Show only certain components
  authenticatorComponents: [
    <SignIn />,
    <ConfirmSignIn />,
    <RequireNewPassword />,
    <ForgotPassword />,
    <Loading />
  ]
});