import Storage from '@aws-amplify/storage';
import bsCustomFileInput from 'bs-custom-file-input';
import $ from 'jquery';
import React, { Component } from 'react';

class S3CsvUpload extends Component {
    componentDidMount() {
        bsCustomFileInput.init();
        $('#s3CsvModal').on('hidden.bs.modal', () => {
            document.getElementById("csvUploadError").classList.add('d-none');
          })
    }

    onSubmit(e) {
        var csvUploadingSpinner = document.getElementById('csvUploadingSpinner');
        var csvUploadError = document.getElementById('csvUploadError');
        csvUploadingSpinner.classList.remove('d-none');
        e.preventDefault();
        const files = document.getElementById('fileInput').files;
        if (files.length > 0) {
            const file = files[0];
            const filename = new Date().toISOString() + file.name;
            Storage.put(filename, file, {
                level: 'private',
                contentType: 'text/csv'
            })
            .then(() => {
                csvUploadError.classList.add('d-none');
                csvUploadingSpinner.classList.add('d-none');
                document.getElementById('fileInputForm').reset();
                $('#s3CsvModal').modal('hide');
            })
            .catch(error => {
                console.log(error);
                csvUploadError.innerHTML = error.message;
                csvUploadError.classList.remove('d-none');
                csvUploadingSpinner.classList.add('d-none');   
            });
        }
    }

    render() {
        return (
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">CSV Upload</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div id="csvUploadError" className="alert alert-warning d-none" role="alert"></div>
                    <form id='fileInputForm'>
                        <div className="custom-file">
                            <input type="file" id="fileInput" className="custom-file-input" accept=".csv" />
                            <label className="custom-file-label" htmlFor="fileInput">Choose file</label>
                        </div>
                    </form>
                </div>
                <div id="fileName"></div>
                <div id="fileSize"></div>
                <div id="fileType"></div>
                <div className="modal-footer">
                    <div id="csvUploadingSpinner" className="spinner-border d-none" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <button type="button" id="upload-object-button" className="btn btn-primary" onClick={(e) => this.onSubmit(e)}>Upload</button>
                </div>
                <div id="progressNumber"></div>
            </div>
        )
    }
}

export default function TransitionsModal() {
    return (
        <div className="col-md-auto">
            <button type="button" className="nav-link btn btn-primary" data-toggle="modal" data-target="#s3CsvModal">
                Upload
            </button>
            <div className="modal fade" id="s3CsvModal" tabIndex="-1" role="dialog" aria-labelledby="s3CsvModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <S3CsvUpload />
                </div>
            </div>
        </div>
    )
}