import API, { graphqlOperation } from '@aws-amplify/api';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useReducer } from 'react';
import * as queries from './graphql/queries';
import WebSocketController from './WebSocket';

let controller = new WebSocketController();
var client = controller.webSocket;

// Action Types
const QUERY = 'QUERY';

var date = new Date().toISOString().substring(0, 10);
var hour = new Date().toISOString().substring(11, 13);

var initialState = {
  mlcsurmcCallLogs: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case QUERY:
      return { ...state, mlcsurmcCallLogs: action.mlcsurmcCallLogs };
    default:
      return state;
  }
}

function ReadableBritishDate(dateStr) {
  const date = new Date(dateStr);
  return dateStr.replace(/T|\.\d{3}Z/g, ' ').replace(/^\d{4}-[01]\d-[0-3]\d/, `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`).trim();
}

function CallLog() {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function getData() {
    const latestMlcsurmcCallLog = await API.graphql(graphqlOperation(queries.listMlcsurmcCallLogs, {
      filter: {
        DateTime: {
          eq: `${date}T${hour}`
        }
      },
      limit: 1000
    }));
    dispatch({ type: QUERY, mlcsurmcCallLogs: latestMlcsurmcCallLog.data.listMlcsurmcCallLogs.items });
  }

  useEffect(() => {
    getData();
    client.onopen = () => {
      console.log('WebSocket Client Connected');
    };
    client.onmessage = (message) => {
      const data = JSON.parse(message.data);
      if (data.action === "onDynamoDBTableMutation" && data.payload.TableName === "mlcsurmc-call-log"){
          getData();
      }
    };
  }, []);

  const columns = [
    {
      name: 'ICGN',
      label: 'ICGN',
      options: {
        filter: false
      }
    },
    {
      name: 'CustomerPhoneNumber',
      label: 'Number Dialed',
      options: {
        filter: false
      }
    },
    {
      name: 'Outcome',
      label: 'Outcome'
    },
    {
      name: 'ContactAttempt',
      label: 'Contact Attempt'
    },
    {
      name: 'InitiationTimestamp',
      label: 'Call Time',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return ReadableBritishDate(value);
        }
      }
    }
  ]

  const options = {
    selectableRows: "none",
    customToolbar: CustomToolbar,
    elevation: 0,
    print: false,
    download: false,
    viewColumns: false,
    setRowProps: (row) => {
      switch(row[2]) {
        case "HungUp1":
        case "HungUp2":
        case "HungUp3":
        case "Mishear":
        case "LeftVoicemail":
            return { className: "table-warning" };
        case "Refused":
        case "CallGuardian":
            return { className: "table-primary" };
        case "Transferred":
            return { className: "table-info" };
        case "TransferSuccess":
            return { className: "table-success" };
        case "TransferFail":
        case "TransferTimeout":
        case "TransferError":
            return { className: "table-danger" };
        default:
            return { className: '' };
      }
    }
  }

  function CustomToolbar() {
    return (
      <div className="form-inline">
        <div className="form-group ml-auto">
          <input
            type="date"
            className="form-control mr-sm-2"
            defaultValue={date}
            onChange={(event) => {
              date = event.target.value;
              getData();
            }}
          />
          <input
            type="number"
            className="form-control"
            defaultValue={hour}
            min="0"
            max="23"
            onChange={(event) => {
              hour = event.target.value.padStart(2, "0");
              getData();
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <MUIDataTable
        columns={columns}
        data={state.mlcsurmcCallLogs}
        options={options}
      />
    </div>
  )
}

export default CallLog;