import API, { graphqlOperation } from '@aws-amplify/api';
import PubSub from '@aws-amplify/pubsub';
import React, { useEffect, useReducer } from 'react';
import uuid from 'uuid';
import awsconfig from './aws-exports';
import { listMlcsurmcImports } from './graphql/queries';
import { onCreateMlcsurmcImport } from './graphql/subscriptions';
import WebSocketController from './WebSocket';

API.configure(awsconfig);
PubSub.configure(awsconfig);

let controller = new WebSocketController();
var client = controller.webSocket;

// Action Types
const QUERY = 'QUERY';
const SUBSCRIPTION = 'SUBSCRIPTION';

const initialState = {
    mlcsurmcImports: [],
};

const reducer = (state, action) => {
    switch (action.type) {
        case QUERY:
            return { ...state, mlcsurmcImports: action.mlcsurmcImports };
        case SUBSCRIPTION:
            return { ...state, mlcsurmcImports: [...state.mlcsurmcImports, action.mlcsurmcImport] }
        default:
            return state;
    }
};

async function getMlcsurmcImport(timestamp, nextToken) {
    var mlcsurmcImportData;
    try {
        mlcsurmcImportData = await API.graphql(graphqlOperation(listMlcsurmcImports, {
            filter: {
                ImportTimestamp: {
                    eq: timestamp
                }
            },
            limit: 1000,
            nextToken
        }));
    }
    catch (error) {
        console.log(error);
        return (error);
    }
    return (mlcsurmcImportData)
}

function ReadableBritishDate(dateStr) {
    const date = new Date(dateStr);
    return dateStr.replace(/T|\.\d{3}Z/g, ' ').replace(/^\d{4}-[01]\d-[0-3]\d/, `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`).trim();
}

function ImportData() {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        async function getData() {
            var latestMlcsurmcImport = await getMlcsurmcImport("Latest");
            while (latestMlcsurmcImport.data.listMlcsurmcImports.items.length === 0 && latestMlcsurmcImport.data.listMlcsurmcImports.nextToken !== null) {
                latestMlcsurmcImport = await getMlcsurmcImport("Latest", latestMlcsurmcImport.data.listMlcsurmcImports.nextToken);
            }
            if (latestMlcsurmcImport.data.listMlcsurmcImports.items.length === 1) {
                const latestImportTimestamp = latestMlcsurmcImport.data.listMlcsurmcImports.items[0].LatestImportTimestamp;
                var mlcsurmcImportData = await getMlcsurmcImport(latestImportTimestamp)
                while (mlcsurmcImportData.data.listMlcsurmcImports.items.length === 0 && mlcsurmcImportData.data.listMlcsurmcImports.nextToken !== null) {
                    mlcsurmcImportData = await getMlcsurmcImport(latestImportTimestamp, mlcsurmcImportData.data.listMlcsurmcImports.nextToken);
                }
                if (mlcsurmcImportData.data.listMlcsurmcImports.items.length === 1) {
                    dispatch({ type: QUERY, mlcsurmcImports: mlcsurmcImportData.data.listMlcsurmcImports.items });
                }
            }
        }
        getData();
        client.onopen = () => {
          console.log('WebSocket Client Connected');
        };
        client.onmessage = (message) => {
          const data = JSON.parse(message.data);
          if (data.action === "onDynamoDBTableMutation" && data.payload.TableName === "mlcsurmc-import"){
              getData();
          }
        };

        const subscription = API.graphql(graphqlOperation(onCreateMlcsurmcImport)).subscribe({
            next: (eventData) => {
                const mlcsurmcImports = eventData.value.data.onCreateMlcsurmcImports;
                dispatch({ type: SUBSCRIPTION, mlcsurmcImports });
            }
        });

        return () => subscription.unsubscribe();
    }, []);

    return (
        <div className="col-md-auto">
            {state.mlcsurmcImports.map((mlcsurmcImport) =>
                <ul className="nav" key={uuid}>
                    <li className="nav-item">
                        <span className="nav-link">Filename: {mlcsurmcImport.Filename.replace(/.+\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/g, '')}</span>
                    </li>
                    <li className="nav-item">
                        <span className="nav-link">Uploaded: {ReadableBritishDate(mlcsurmcImport.ImportTimestamp)}</span>
                    </li>
                    <li className="nav-item">
                        <span className="nav-link">Total: {mlcsurmcImport.InitialCallVolume}</span>
                    </li>
                    <li className="nav-item">
                        <span className="nav-link">Processed: {mlcsurmcImport.InitialCallVolume - mlcsurmcImport.RemainingCallVolume}</span>
                    </li>
                    <li className="nav-item">
                        <span className="nav-link">Remaining: {mlcsurmcImport.RemainingCallVolume}</span>
                    </li>
                    <li className="nav-item">
                        <span className="nav-link">% Complete: {Math.floor((mlcsurmcImport.InitialCallVolume - mlcsurmcImport.RemainingCallVolume) / mlcsurmcImport.InitialCallVolume * 100)}</span>
                    </li>
                </ul>
            )}
        </div>
    );
}

export default ImportData;