let instance = null;

class WebSocketController {
    constructor() {
        if (!instance) {
            instance = this;
        }
        this.webSocket = new WebSocket('wss://cl78xphte7.execute-api.eu-west-2.amazonaws.com/Dev');
        return instance;
    }
}

export default WebSocketController;