import API, { graphqlOperation } from '@aws-amplify/api';
import PubSub from '@aws-amplify/pubsub';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useReducer } from 'react';
import awsconfig from './aws-exports';
import { listMlcsurmcImports } from './graphql/queries';
import { onCreateMlcsurmcImport } from './graphql/subscriptions';
import WebSocketController from './WebSocket';

API.configure(awsconfig);
PubSub.configure(awsconfig);

let controller = new WebSocketController();
var client = controller.webSocket;

// Action Types
const QUERY = 'QUERY';
const SUBSCRIPTION = 'SUBSCRIPTION';

const initialState = {
  mlcsurmcImports: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case QUERY:
      return { ...state, mlcsurmcImports: action.mlcsurmcImports };
    case SUBSCRIPTION:
      return { ...state, mlcsurmcImports: [...state.mlcsurmcImports, action.mlcsurmcImport] }
    default:
      return state;
  }
};

async function getMlcsurmcImport(timestamp, nextToken) {
  var mlcsurmcImportData;
  try {
    mlcsurmcImportData = await API.graphql(graphqlOperation(listMlcsurmcImports, {
      filter: {
        ImportTimestamp: {
          eq: timestamp
        }
      },
      limit: 1000,
      nextToken
    }));
  }
  catch (error) {
    console.log(error);
    return (error);
  }
  return (mlcsurmcImportData)
}

function Outcomes() {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function getData() {
      var latestMlcsurmcImport = await getMlcsurmcImport("Latest");
      while (latestMlcsurmcImport.data.listMlcsurmcImports.items.length === 0 && latestMlcsurmcImport.data.listMlcsurmcImports.nextToken !== null) {
        latestMlcsurmcImport = await getMlcsurmcImport("Latest", latestMlcsurmcImport.data.listMlcsurmcImports.nextToken);
      }
      if (latestMlcsurmcImport.data.listMlcsurmcImports.items.length === 1) {
        const latestImportTimestamp = latestMlcsurmcImport.data.listMlcsurmcImports.items[0].LatestImportTimestamp;
        var mlcsurmcImportData = await getMlcsurmcImport(latestImportTimestamp);
        while (mlcsurmcImportData.data.listMlcsurmcImports.items.length === 0 && mlcsurmcImportData.data.listMlcsurmcImports.nextToken !== null) {
          mlcsurmcImportData = await getMlcsurmcImport(latestImportTimestamp, mlcsurmcImportData.data.listMlcsurmcImports.nextToken);
        }
        if (mlcsurmcImportData.data.listMlcsurmcImports.items.length === 1) {
          var mlcsurmcImports = mlcsurmcImportData.data.listMlcsurmcImports.items[0];
          for (var key in mlcsurmcImports) {
            if (key.startsWith("Result_Outcome_") && mlcsurmcImports[key] === null) {
              mlcsurmcImports[key] = 0;
            }
          }
          dispatch({ type: QUERY, mlcsurmcImports: [mlcsurmcImports] });
        }
      }
    }
    getData();
    client.onopen = () => {
      console.log('WebSocket Client Connected');
    };
    client.onmessage = (message) => {
      const data = JSON.parse(message.data);
      if (data.action === "onDynamoDBTableMutation" && data.payload.TableName === "mlcsurmc-import"){
          getData();
      }
    };

    const subscription = API.graphql(graphqlOperation(onCreateMlcsurmcImport)).subscribe({
      next: (eventData) => {
        const mlcsurmcImport = eventData.value.data.onCreateMlcsurmcImports;
        dispatch({ type: SUBSCRIPTION, mlcsurmcImport });
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const columns = [
    {
      name: 'Result_Outcome_LeftVoicemail',
      label: 'Left voicemail',
      options: {
        hint: 'The call went to an answering machine and voicemail was left.'
      }
    },
    {
      name: 'Result_Outcome_CallGuardian',
      label: 'Call guardian',
      options: {
        hint: 'The callees phone was protected. An agent must call them.'
      }
    },
    {
      name: 'Result_Outcome_HungUp1',
      label: 'Hung up 1',
      options: {
        hint: 'The callee hung up straight away.'
      }
    },
    {
      name: 'Result_Outcome_HungUp2',
      label: 'Hung up 2',
      options: {
        hint: 'The callee hung up after knowing who called.'
      }
    },
    {
      name: 'Result_Outcome_HungUp3',
      label: 'Hung up 3',
      options: {
        hint: 'The callee hung up before being asked if they wanted to talk to an agent.'
      }
    },
    {
      name: 'Result_Outcome_Mishear',
      label: 'Mishear',
      options: {
        hint: 'The system could not determine if the callee wanted to talk to an agent.'
      }
    },
    {
      name: 'Result_Outcome_Refused',
      label: 'Refused',
      options: {
        hint: 'The callee did not want to talk to an agent.'
      }
    },
    {
      name: 'Result_Outcome_Transferred',
      label: 'Transferred',
      options: {
        hint: 'The system began to transfer the callee to an agent.'
      }
    },
    {
      name: 'Result_Outcome_TransferSuccess',
      label: 'Transfer success',
      options: {
        hint: 'The callee was successfully transferred to an agent.'
      }
    },
    {
      name: 'Result_Outcome_TransferFail',
      label: 'Transfer fail',
      options: {
        hint: 'The callee failed to transfer to an agent.'
      }
    },
    {
      name: 'Result_Outcome_TransferTimeout',
      label: 'Transfer timeout',
      options: {
        hint: 'The callee was not transferred to an agent within 30 seconds.'
      }
    },
    {
      name: 'Result_Outcome_TransferError',
      label: 'Transfer error',
      options: {
        hint: 'There was an error transferrign the callee to an agent.'
      }
    }
  ]

  const options = {
    pagination: false,
    selectableRows: "none",
    elevation: 0,
    rowHover: false,
    sort: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    filter: false
  }

  return (
    <MUIDataTable
      columns={columns}
      data={state.mlcsurmcImports}
      options={options}
    />
  );
}

export default Outcomes;