/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMlcsurmcCallLog = `mutation CreateMlcsurmcCallLog(
  $input: CreateMlcsurmcCallLogInput!
  $condition: ModelMlcsurmcCallLogConditionInput
) {
  createMlcsurmcCallLog(input: $input, condition: $condition) {
    ContactId
    CallInProgess
    CallType
    ContactAttempt
    CustomerPhoneNumber
    DateTime
    DisconnectTimestamp
    ICGN
    InitiationTimestamp
    Message
    Outcome
  }
}
`;
export const updateMlcsurmcCallLog = `mutation UpdateMlcsurmcCallLog(
  $input: UpdateMlcsurmcCallLogInput!
  $condition: ModelMlcsurmcCallLogConditionInput
) {
  updateMlcsurmcCallLog(input: $input, condition: $condition) {
    ContactId
    CallInProgess
    CallType
    ContactAttempt
    CustomerPhoneNumber
    DateTime
    DisconnectTimestamp
    ICGN
    InitiationTimestamp
    Message
    Outcome
  }
}
`;
export const deleteMlcsurmcCallLog = `mutation DeleteMlcsurmcCallLog(
  $input: DeleteMlcsurmcCallLogInput!
  $condition: ModelMlcsurmcCallLogConditionInput
) {
  deleteMlcsurmcCallLog(input: $input, condition: $condition) {
    ContactId
    CallInProgess
    CallType
    ContactAttempt
    CustomerPhoneNumber
    DateTime
    DisconnectTimestamp
    ICGN
    InitiationTimestamp
    Message
    Outcome
  }
}
`;
export const createMlcsurmcSystemSetting = `mutation CreateMlcsurmcSystemSetting(
  $input: CreateMlcsurmcSystemSettingInput!
  $condition: ModelMlcsurmcSystemSettingConditionInput
) {
  createMlcsurmcSystemSetting(input: $input, condition: $condition) {
    id
    Timestamp
    StartTime
    EndTime
    PauseUntil
  }
}
`;
export const updateMlcsurmcSystemSetting = `mutation UpdateMlcsurmcSystemSetting(
  $input: UpdateMlcsurmcSystemSettingInput!
  $condition: ModelMlcsurmcSystemSettingConditionInput
) {
  updateMlcsurmcSystemSetting(input: $input, condition: $condition) {
    id
    Timestamp
    StartTime
    EndTime
    PauseUntil
  }
}
`;
export const deleteMlcsurmcSystemSetting = `mutation DeleteMlcsurmcSystemSetting(
  $input: DeleteMlcsurmcSystemSettingInput!
  $condition: ModelMlcsurmcSystemSettingConditionInput
) {
  deleteMlcsurmcSystemSetting(input: $input, condition: $condition) {
    id
    Timestamp
    StartTime
    EndTime
    PauseUntil
  }
}
`;
export const createMlcsurmcImport = `mutation CreateMlcsurmcImport(
  $input: CreateMlcsurmcImportInput!
  $condition: ModelMlcsurmcImportConditionInput
) {
  createMlcsurmcImport(input: $input, condition: $condition) {
    ImportTimestamp
    Filename
    InitialCallVolume
    RemainingCallVolume
    LatestImportTimestamp
    Result_Outcome_LeftVoicemail
    Result_Outcome_CallGuardian
    Result_Outcome_HungUp1
    Result_Outcome_HungUp2
    Result_Outcome_HungUp3
    Result_Outcome_Mishear
    Result_Outcome_Refused
    Result_Outcome_Transferred
    Result_Outcome_TransferSuccess
    Result_Outcome_TransferFail
    Result_Outcome_TransferTimeout
    Result_Outcome_TransferError
  }
}
`;
export const updateMlcsurmcImport = `mutation UpdateMlcsurmcImport(
  $input: UpdateMlcsurmcImportInput!
  $condition: ModelMlcsurmcImportConditionInput
) {
  updateMlcsurmcImport(input: $input, condition: $condition) {
    ImportTimestamp
    Filename
    InitialCallVolume
    RemainingCallVolume
    LatestImportTimestamp
    Result_Outcome_LeftVoicemail
    Result_Outcome_CallGuardian
    Result_Outcome_HungUp1
    Result_Outcome_HungUp2
    Result_Outcome_HungUp3
    Result_Outcome_Mishear
    Result_Outcome_Refused
    Result_Outcome_Transferred
    Result_Outcome_TransferSuccess
    Result_Outcome_TransferFail
    Result_Outcome_TransferTimeout
    Result_Outcome_TransferError
  }
}
`;
export const deleteMlcsurmcImport = `mutation DeleteMlcsurmcImport(
  $input: DeleteMlcsurmcImportInput!
  $condition: ModelMlcsurmcImportConditionInput
) {
  deleteMlcsurmcImport(input: $input, condition: $condition) {
    ImportTimestamp
    Filename
    InitialCallVolume
    RemainingCallVolume
    LatestImportTimestamp
    Result_Outcome_LeftVoicemail
    Result_Outcome_CallGuardian
    Result_Outcome_HungUp1
    Result_Outcome_HungUp2
    Result_Outcome_HungUp3
    Result_Outcome_Mishear
    Result_Outcome_Refused
    Result_Outcome_Transferred
    Result_Outcome_TransferSuccess
    Result_Outcome_TransferFail
    Result_Outcome_TransferTimeout
    Result_Outcome_TransferError
  }
}
`;
